import React, { useState, useEffect, useRef } from "react";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdDelete,
  MdEdit,
  MdPrint,
} from "react-icons/md";
import html2pdf from "html2pdf.js";
import OtherPdf from "./OtherPdf";
import DocumentEditor from "./DocumentEditor";
import TestPdf from "./TestPdf";
import axios from "axios";
// Example form submission function

const App = () => {
  const [questions, setQuestions] = useState([
    "Med Name",
    "Dose",
    "Frequency",
    "Status",
    "Edited",
  ]);
  const [showModal, setShowModal] = useState(false);
  const [tempMeds, setTempMeds] = useState({
    name: "",
    dose: "",
    status: "",
    frequency: "",
    edited: "No",
  });
  const [meds, setMeds] = useState([
    {
      name: "Lisinopril",
      dose: "10 mg",
      status: "Active",
      frequency: "once daily",
      edited: "No",
    },
    {
      name: "Atorvastatin",
      dose: "20 mg",
      status: "Active",
      frequency: "once daily in the evening",
      edited: "No",
    },
    {
      name: "Metformin",
      dose: "500 mg",
      status: "New",
      frequency: "twice daily with meals",
      edited: "No",
    },
    {
      name: "Albuterol",
      dose: "2 puffs",
      status: "Active",
      frequency: "every 4-6 hours as needed",
      edited: "No",
    },
    {
      name: "Amoxicillin",
      dose: "500 mg",
      status: "Discontinued",
      frequency: "three times daily",
      edited: "No",
    },
    {
      name: "Levothyroxine",
      dose: "50 mcg",
      status: "Active",
      frequency: "once daily on an empty stomach",
      edited: "No",
    },
    {
      name: "Amlodipine",
      dose: "5 mg",
      status: "New",
      frequency: "once daily",
      edited: "No",
    },
    {
      name: "Sertraline",
      dose: "50 mg",
      status: "Active",
      frequency: "once daily",
      edited: "No",
    },
    {
      name: "Omeprazole",
      dose: "20 mg",
      status: "Unknown",
      frequency: "once daily before a meal",
      edited: "No",
    },
    {
      name: "Ibuprofen",
      dose: "400 mg",
      status: "Active",
      frequency: "every 4-6 hours as needed",
      edited: "No",
    },
  ]);

  const [show, setShow] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [caseManager, setCaseManager] = useState("");
  const [patientName, setPatientName] = useState("Test Patient");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");

  const type = ["Active", "Discontinued", "Unknown"];

  const contentRef = useRef(null);

  const convertToPDF = () => {
    setShow(true);
    const element = contentRef.current;

    if (element) {
      html2pdf().from(element).save("MedRec.pdf");
      setTimeout(() => setShow(false), 500);
    }
  };

  const medChanger = (index, b) => {
    let x = [...meds];
    x[index].status = b;
    setMeds(x);
  };

  useEffect(() => {
    console.log(tempMeds);
  }, [tempMeds]);

  const addMeds = () => {
    setMeds([...meds, tempMeds]);
    setTempMeds({
      name: "",
      dose: "",
      status: "",
      frequency: "",
      edited: "No",
    });
    console.log(meds);
  };

  const deleteMedication = (a) => {
    let x = [...meds];
    x = x.filter((b) => b != a);
    setMeds(x);
  };

  const changeMeds = (name, value, index) => {
    let x = [...meds];

    x[index][name] = value;
    x[index].edited = "Yes";
    setMeds(x);
  };

  const handleSubmit = async (formData) => {
    try {
      // Set responseType to 'blob' to handle the binary PDF data
      const response = await axios.post(
        "https://admlabz.com/serve/generate-pdf",
        formData,
        {
          responseType: "blob", // Important for handling the PDF binary data
        }
      );
      // "http://203.161.49.36:3000/generate-pdf"
      // Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });

      // Create a URL for the blob
      const fileURL = URL.createObjectURL(file);

      const randomNumber = Math.floor(Math.random() * (1000 - 100 + 1)) + 100;

      // Create a temporary link to download the file
      const downloadLink = document.createElement("a");
      downloadLink.href = fileURL;
      downloadLink.setAttribute("download", `MedRec#${randomNumber}.pdf`); // The default name for the downloaded file
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up by revoking the Blob URL and removing the temporary link
      URL.revokeObjectURL(fileURL);
      downloadLink.remove();
    } catch (error) {
      console.error("Error generating PDF:", error);
      alert(JSON.stringify(error));
    }
  };

  function getFormattedDate(date) {
    let day = date.getDate().toString().padStart(2, "0"); // Ensures the day is two digits
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensures the month is two digits; +1 because getMonth() is zero-indexed
    let year = date.getFullYear(); // Gets the full year

    return `${month}/${day}/${year}`; // Formats the date in MM/DD/YYYY format
  }

  let currentDate = new Date(); // Gets the current date

  function addDaysToDate(date, days) {
    let result = new Date(date); // Create a new Date object to avoid mutating the original date
    result.setDate(result.getDate() + days); // Adds the days
    return result;
  }

  let newDate = addDaysToDate(currentDate, 7);

  function capitalizeWords(str) {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  }

  const changeTempEdit = (a, e) => {
    let x = { ...tempEdit };

    switch (a) {
      case "name":
        x.name = e;
        setTempEdit(x);
        break;
      case "dose":
        x.dose = e;
        setTempEdit(x);
        break;
      case "frequency":
        x.frequency = e;
        setTempEdit(x);
        break;
    }
  };

  const submitTempEdit = () => {
    let m = [...meds];
    m[tempEditIndex] = tempEdit;
    m[tempEditIndex].edited = "Yes";
    setMeds(m);
    noShowModal();
  };
  const modals = () => {
    return (
      <>
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-[1000] flex justify-center items-center"
          onClick={noShowModal}
        >
          <div
            className="bg-white p-4 rounded-lg shadow-lg w-full md:w-1/2 min-h-1/2 flex justify-center "
            onClick={(e) => e.stopPropagation()}
          >
            <div className="text-black w-full p-2">
              <div className="text-center font-bold text-lg">
                Edit Medication
              </div>
              <div className="text-sm text-gray-400 pl-2 ">Medication</div>
              <input
                className="border-gray-200 border-2 p-4 w-full"
                value={tempEdit.name}
                onChange={(e) => changeTempEdit("name", e.target.value)}
              />
              <div className="text-sm text-gray-400 pl-2 mt-2">Dose</div>
              <input
                className="border-gray-200 border-2 p-4 w-full"
                value={tempEdit.dose}
                onChange={(e) => changeTempEdit("dose", e.target.value)}
              />
              <div className="text-sm text-gray-400 pl-2 mt-2">Frequency</div>
              <input
                className="border-gray-200 border-2 p-4 w-full"
                value={tempEdit.frequency}
                onChange={(e) => changeTempEdit("frequency", e.target.value)}
              />
              <button
                className="rounded-lg p-4 bg-blue-500 text-white w-full mt-4"
                onClick={submitTempEdit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const showModalsTeam = (a, i) => {
    document.body.style.overflow = "hidden";
    setShowTeam(true);
  };

  const [showTeam, setShowTeam] = useState(false);
  const noShowModalTeam = () => {
    document.body.style.overflow = "";
    setShowTeam(false);
  };

  const submitTeamEdit = () => {
    noShowModalTeam();
  };
  const editTeamModal = () => {
    return (
      <>
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-[1000] flex justify-center items-center"
          onClick={noShowModalTeam}
        >
          <div
            className="bg-white p-4 rounded-lg shadow-lg w-full md:w-1/2 min-h-1/2 flex justify-center "
            onClick={(e) => e.stopPropagation()}
          >
            <div className="text-black w-full p-2">
              <div className="text-center font-bold text-lg">Edit Team</div>
              <div className="text-sm text-gray-400 pl-2 ">Case Manager</div>
              <input
                className="w-full p-4 border-2"
                onChange={(e) => setCaseManager(e.target.value)}
                value={caseManager}
                placeHolder="Case Manager"
                onClick={() => setCaseManager("")}
              />
              <div className="text-sm text-gray-400 pl-2 mt-2">
                Provider Name
              </div>
              <input
                className="w-full p-4 border-2"
                onChange={(e) => setProviderName(e.target.value)}
                value={providerName}
                placeHolder="Provider Name"
                onClick={() => setProviderName("")}
              />
              <div className="text-sm text-gray-400 pl-2 mt-2">
                Patient Name
              </div>
              <input
                className="w-full p-4 border-2"
                onChange={(e) => setPatientName(e.target.value)}
                value={patientName}
                placeHolder="Test Patient"
                onClick={() => setPatientName("")}
                disabled
              />
              <div className="text-sm text-gray-400 pl-2 mt-2">
                Contact Number
              </div>
              <input
                className="w-full p-4 border-2"
                onChange={(e) => setContact(e.target.value)}
                value={contact}
                placeHolder="Contact Phone #"
                onClick={() => setContact("")}
              />
              <div className="text-sm text-gray-400 pl-2 mt-2">
                Contact Email
              </div>
              <input
                className="w-full p-4 border-2"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeHolder="Contact Email"
                onClick={() => setEmail("")}
              />
              <button
                className="rounded-lg p-4 bg-blue-500 text-white w-full mt-4"
                onClick={submitTeamEdit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const [tempEdit, setTempEdit] = useState({});
  const [tempEditIndex, setTempEditIndex] = useState(0);

  const showModals = (a, i) => {
    document.body.style.overflow = "hidden";
    setShow(true);
    setTempEdit(a);
    setTempEditIndex(i);
  };

  const noShowModal = () => {
    document.body.style.overflow = "";
    setShow(false);
  };

  return (
    <div className="w-full h-full p-2 md:p-8 min-h-[75vh] pb-32">
      {show && modals()}
      {showTeam && editTeamModal()}
      <div
        className="fixed bottom-0 left-0 bg-blue-500 p-4 w-full "
        style={{ zIndex: 100 }}
      >
        <button
          className="flex flex-col justify-center items-center w-full"
          onClick={() =>
            handleSubmit({
              name: "John Smith",
              medication: meds,
              tableData: meds,
              date: getFormattedDate(currentDate),
              provider: providerName != "" ? providerName : "Smith",
              endDate: getFormattedDate(newDate),
              caseManager: caseManager != "" ? caseManager : "Case Management",
              patientName: patientName != "" ? patientName : "John Smith",
              contact: contact != "" ? contact : "1(888) 888-8888",
              email: email != "" ? email : "testemail@test.com",
            })
          }
        >
          <MdPrint size={30} color={"white"} />
          <div className="w-full text-white">Generate</div>
        </button>
      </div>
      <div className="fixed top-0 left-0 bg-blue-500 p-4 w-full z-10 flex justify-between items-center">
        <div className="md:flex-1"></div> {/* This div acts as a spacer */}
        <div className="text-white font-bold">Medication Reconciliation</div>
        <div className="flex-1 flex justify-end">
          <button
            className="bg-white text-blue-500 font-bold py-2 px-4 rounded"
            onClick={showModalsTeam}
          >
            Edit Team
          </button>
        </div>
      </div>

      <div className="pt-24">
        <div className="grid grid-cols-2 lg:grid-cols-3 w-full p-2 border-gray-500 bg-gray-200">
          <div className="col-span-2 lg:col-span-3 w-full lg:text-lg flex justify-center items-center font-bold">
            Add Medicaiton
          </div>
          <div className="p-2">
            <input
              className="w-full p-4 border-2"
              placeholder="Enter Medication Name"
              value={tempMeds.name}
              onChange={(e) => {
                setTempMeds({
                  ...tempMeds,
                  name: capitalizeWords(e.target.value),
                });
              }}
              onClick={() => setTempMeds({ ...tempMeds, name: "" })}
            />
          </div>
          <div className="p-2 ">
            <input
              className="w-full p-4 border-2"
              placeholder="Enter Dose"
              value={tempMeds.dose}
              onChange={(e) => {
                setTempMeds({
                  ...tempMeds,
                  dose: capitalizeWords(e.target.value),
                });
              }}
              onClick={() => setTempMeds({ ...tempMeds, dose: "" })}
            />
          </div>
          <div className="p-2 lg:col-span-1 col-span-2 ">
            <input
              className="w-full p-4 border-2 "
              placeholder="Enter Frequency"
              value={tempMeds.frequency}
              onChange={(e) => {
                setTempMeds({
                  ...tempMeds,
                  frequency: e.target.value.toUpperCase(),
                });
              }}
              onClick={() => setTempMeds({ ...tempMeds, frequency: "" })}
            />
          </div>
          <div className="border-black w-full col-span-2 lg:col-span-3 text-center p-2">
            <div className="w-full flex justify-around ">
              {type.map((b) => (
                <button
                  className={`w-full ${
                    b.toLowerCase() == tempMeds.status.toLowerCase()
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300"
                  } p-4`}
                  onClick={() => setTempMeds({ ...tempMeds, status: b })}
                >
                  {b}
                </button>
              ))}
            </div>
          </div>
          <div className="p-2 w-full col-span-2 lg:col-span-3">
            <button
              className={`${
                tempMeds.name != "" &&
                tempMeds.dose != "" &&
                tempMeds.status != ""
                  ? "bg-blue-600 text-white"
                  : "bg-gray-300 text-gray-100 "
              } p-4  w-full`}
              disabled={
                tempMeds.name != "" &&
                tempMeds.dose != "" &&
                tempMeds.status != ""
                  ? false
                  : true
              }
              onClick={addMeds}
            >
              Add Medication
            </button>
          </div>
        </div>
        <div className="font-bold text-center">Active Medications</div>
        <div ref={contentRef} className="pb-64">
          <div className="w-full flex flex-wrap mt-2 ">
            {meds.map((a, i) => (
              <>
                <div
                  className={`relative z-0 flex md:justify-around w-full md:w-1/2 p-4 ${
                    a.edited.toLowerCase() == "yes"
                      ? "bg-orange-200"
                      : "bg-gray-100"
                  } border-2   md:flex-grow md:justify-center md:items-center  `}
                >
                  <div className="flex flex-col w-full">
                    <div className="text-lg bg-transparent border-0 w-3/4 text-md">
                      {a.name}
                    </div>
                    <div className="bg-transparent border-0 w-3/4 text-sm">
                      {a.dose}
                    </div>
                    <div className="text-lg bg-transparent border-0 w-3/4 text-sm">
                      {a.frequency}
                    </div>
                  </div>

                  <div className=" flex flex-col md:flex-row  w-full ">
                    {!show
                      ? type.map((b) => (
                          <button
                            className={`w-full justify-center items-center ${
                              b.toLowerCase() == a.status.toLowerCase()
                                ? "bg-blue-500 text-white"
                                : "bg-gray-300"
                            } p-4 text-lg`}
                            onClick={() => medChanger(i, b)}
                          >
                            {b}
                          </button>
                        ))
                      : a.status}
                  </div>

                  <button
                    className="w-full flex-1  p-4 flex items-center justify-center  h-full ml-1"
                    onClick={() => deleteMedication(a)}
                  >
                    <MdDelete size={20} />
                  </button>
                  <button
                    className="w-full flex-1  p-4 flex items-center justify-center  h-full ml-1"
                    onClick={() => showModals(a, i)}
                  >
                    <MdEdit size={20} />
                  </button>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      {
        //<DocumentEditor />
      }
    </div>
  );
};

export default App;
